<template>
    <div class="adresses">
        <div class="entete">
            <div class="fil-ariane">
                <router-link to="/">Accueil</router-link>
                <span> > </span>
                <router-link to="/compte">Mon compte</router-link>
                <span> > </span>
                <router-link to="/commandes">Mes adresses</router-link>
            </div>
            <h1 class="titre" v-html="`${edit ? (creation ? 'Création d\'une ' : 'Edition de l\' ') : 'Détail de l\''}adresse ${intitule}`"></h1>
        </div>
        <div class="carte-arrondie">
            <div class="adresse">
                <div class="adresse-body" v-if="types.length > 0">
                    <div class="adresse-contenu">
                        <div>
                            <adresse
                                v-model="adresse"
                                :type="type"
                                :memorisation="false"
                                :utiliser-defaut="false"
                                :masque-selecteur="true"
                                :readonly="!edit"
                            />
                        </div>

                        <v-snackbar
                            top
                            right
                            :timeout="-1"
                            :color="informationRequete.status"
                            v-model="informationRequete.ouvert"
                        >
                            <div v-html="informationRequete.texte"></div>

                            <template v-slot:action="{ attrs }">
                                <v-btn
                                    text
                                    v-bind="attrs"
                                    @click="fermeInformationRequete"
                                >Fermer</v-btn>
                            </template>
                        </v-snackbar>
                    </div>
                    <div class="adresse-controles">
                        <template v-if="edit">
                            <btn-theme @click="retour"  title="Annuler les modifications et revenir à la liste">Annuler</btn-theme>
                            <div class="spacer"></div>
                            <btn-theme @click="sauveAdresse">Enregistrer</btn-theme>
                        </template>
                        <template v-else>
                            <btn-theme @click="retour" title="Revenir à la liste">Retour</btn-theme>
                            <div class="spacer"></div>
                            <btn-theme class="action" @click="modeEdit" title="Editer cette adresse"><v-icon size="24px">{{ mdiFileDocumentEditOutline }}</v-icon></btn-theme>
                        </template>
                    </div>
                </div>
                <div class="adresse-body" v-else>
                    <v-alert type="warning">
                        Vous n'avez pas la possibilité de créer d'adresses. Veuillez consulter votre administrateur pour effecuter cette opération.
                    </v-alert>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import Api from '../api/api.js';
import {mapGetters} from 'vuex';
import Adresse from "../components/adresse/Adresse";
import BtnTheme from "../components/theme/BtnTheme";
import { mdiFileDocumentEditOutline } from "@mdi/js";

export default {
    name: 'AjoutAdresse',
    components: {
        Adresse,
        BtnTheme,
    },
    data: () => ({
        urlSauveAdresse: process.env.VUE_APP_API_URL + '/adresse/sauve',
        getAdresseURL: process.env.VUE_APP_API_URL + '/adresse/get',
        intitule: '',
        ready: false,
        adresse: {},
        types: [],
        type: '',
        informationRequete: {
            ouvert: false,
            texte: '',
            status: '',
        },
        edit: false,
        creation: false,
        mdiFileDocumentEditOutline
    }),
    computed: {
        ...mapGetters([
            'portail'
        ]),
    },
    methods: {
        initTypeAdresses () {
            //plutôt que de faire des filtres bizarres, le plus simple est de créer la liste de types selon les droits.
            let liste = [];
            if (this.portail.saisie_adresses_livraison) liste.push('livraison');
            if (this.portail.saisie_adresses_facturation) liste.push('facturation');
            this.$set(this, 'types',  liste);
            if (liste.length) this.type = liste[0];
        },
        getAdresse () {
            return Api.get(this.getAdresseURL + '/' + this.adresse.id)
                .then(this.setAdresse)
                .then(this.conserveContenuInitial);
        },
        setAdresse (contenu) {
            this.$set(this, 'adresse', contenu);
            this.ready = true;
        },
        sauveAdresse () {
            let clefs = Object.keys(this.adresse),
                len = clefs.length;
            if (len === 0) {
                this.ouvreInformationRequete("Merci de renseigner les champs pour sauvegarder l'adresse.", 'error');
                return;
            }
            if (this.adresse.intitule === '') this.ouvreInformationRequete("Merci de renseigner l'intitulé de votre adresse.", 'error');

            let data = Object.assign({}, this.adresse);
            if (data.id === '' || data.id === null) delete data.id;
            data.type = this.type;
            data.intitule = this.adresse.intitule;

            Api.post(this.urlSauveAdresse, data)
                .then(() => {
                    this.$router.push({ path: '/adresses' });
                })
                .catch(err => {
                    let error = [],
                        errors = err.response.data.errors,
                        errKeys = Object.keys(errors);

                    if (errKeys.length > 0) {
                        for (let i = 0; i < errKeys.length; i++) {
                            let champ = errKeys[i];
                            if (Array.isArray(champ)) {
                                error.push(errors[champ].join('<br/>'));
                            }
                            else {
                                error.push(errors[champ]);
                            }
                        }
                    }
                    this.ouvreInformationRequete("Une erreur est survenue durant la sauvegarde de l'adresse :<br/>" + error.join('<br/>'), 'error');
                });
        },
        ouvreInformationRequete (texte, status) {
            this.informationRequete = Object.assign(this.informationRequete, { ouvert: true, texte, status });
        },
        fermeInformationRequete () {
            this.informationRequete.ouvert = false;
        },
        retour () {
            window.history.length > 2 ? this.$router.go(-1) : this.$router.push({ path: '/adresses' });
        },
        modeEdit () {
            this.edit = true;
            this.$router.replace({ query: { mode: 'edit' } });
        },
        restoreMode () {
            if (Object.hasOwnProperty.call(this.$route.query, 'mode')) {
                const mode = this.$route.query.mode;
                this.creation = mode === 'new';
                this.edit = mode === 'edit' || this.creation;
            }
        },
        restoreAdresseId () {
            this.adresse.id = this.$route.params.id ?? null;
        },
    },
    mounted() {
        this.initTypeAdresses();
    },
    beforeMount() {
        this.restoreAdresseId();
        this.restoreMode();
        Promise.all([ //on charge tout le temps l'ensemble car on peut passer du mode view à edition
        ]).then(() => {
            if (this.adresse.id) {
                this.getAdresse();
            } else {
                this.ready = true
            }
        });
    }
}

</script>
<style scoped lang="scss">
.adresses {
    & > .carte-arrondie {
        min-width:400px;
        max-width:400px;
        & .adresse {
            width: 100%;
            //height: calc(100vh - 28px);
            margin: auto;
            display: flex;
            flex-direction: column;
            & .adresse-body {
                margin: 12px;
                display: flex;
                flex-direction: column;
                flex: 1 1 auto;
                justify-content: center;

                & .adresse-controles {
                    margin-bottom: 12px;
                    display: flex;
                    flex-direction: row;
                    align-items: baseline;
                    & .spacer{
                        flex-grow: 1;
                    }
                    & .action{
                        margin-left: 5px;
                    }
                }
                & .adresse-contenu {
                    flex-grow: 1;
                    display: flex;
                    flex-direction: column;
                }
            }
            .controles-gauche {
                margin-right: 1.5em;
                padding: 1em;
                //   background-color: #ececec;
                min-width: 250px;
                height: 0;
            }
        }
    }
}
</style>